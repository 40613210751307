<template>
  <div></div>
</template>
<script>
import {mapActions} from 'vuex';
import {LOGOUT_REQUEST} from '@/store/storeActions';
import appNotification, {notificationsTypes} from '@/util/appNotification';

export default {
  async created() {
    await this.LOGOUT_REQUEST();
    appNotification.notify({message: 'Bad credentials!', type: notificationsTypes.danger});
    await this.$router.push({name: 'Login'});
  },
  methods: {
    ...mapActions([
      LOGOUT_REQUEST,
    ]),
  },
};
</script>
